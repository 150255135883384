import React, { useContext, useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { MenuContext } from "../context/MenuContext"
import MainMenu from "../components/Menu/MainMenu"
import Layout from "../components/Layout"
import ContactCard from "../components/Card/ContactCard"
import {
  StyledGrid,
  StyledGridBigContent,
} from "../components/Layout/components.css"
import ElephantFour from "../components/Elephant"
import ElephantFront2 from "../components/Elephant/ElephantFront2"
import MenuButton from "../components/Menu/MenuButton"
import brownLogo from "../images/BrownLaw_Logo_Dark.svg"
import bgPathTop from "../images/bg_top_right.svg"
import bgPathBottom from "../images/bg_bottom_left.svg"

function Connect() {
  const [isFormVisible, setFormVisibility] = useState(false)

  const { isMenuOpen } = useContext(MenuContext)

  const formExit = () => {
    setFormVisibility(false)
  }
  const cardClickHandler = () => {
    setFormVisibility(true)
  }

  // animation               transition={{delay: isMenuOpen ? 0: 1, type: "spring", stiffness: 200, duration:1 }}

  const parent = {
    open: { x: "-10%", opacity: 1 },
    closed: { x: 0, opacity: 0.5 },
  }
  const menuElephantVariants = {
    open: { x: 0 },
    closed: { x: "-50%" },
  }
  const logoVariants = {
    open: { x: 0 },
    closed: { x: `25%` },
  }
  const pageLoad = {
    closed: { x: "-10%", y: "10%", opacity: 0.5 },
    open: { x: 0, y: 0, opacity: 1 },
  }
  const elephantLoad2 = {
    open: { y: 0, x: "-100%", opacity: 1 },
    closed: { y: "-100%", x: 0, opacity: 0 },
    exit: { x: "-50%", opacity: 0 },
  }
  const elephantLoad = {
    closed: { y: "-100%", x: "-100%", opacity: 0 },
    open: { y: 0, x: 0, opacity: 1 },
  }

  const divLoad = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
  }
  return (
    <Layout>
      <StyledGridBigContent
        initial="closed"
        animate="open"
        variants={elephantLoad}
      >
        {/* <motion.div className="col-1"> */}
        <AnimatePresence>
          {!isMenuOpen && (
            <motion.div
              animate={isMenuOpen ? `closed` : `open`}
              variants={elephantLoad}
              exit="closed"
              key="container"
              initial="closed"
              transition={{
                when: "beforeChildren",
                staggerChildren: 0.3,
                // duration: .5,
              }}
              className="col-1"
            >
              <ElephantFour key="elephant" />
            </motion.div>
          )}
        </AnimatePresence>
        {/* </motion.div> */}
        {!isMenuOpen && (
          <>
            <motion.div
              className="col-2"
              initial="hidden"
              animate="visible"
              variants={parent}
              layoutTransition
              style={{ minHeight: `25em` }}
            >
              <div>
                <motion.img
                  src={brownLogo}
                  alt="Brown Logo"
                  style={{
                    height: 125,
                    width: `auto`,
                    position: `relative`,
                    left: `-105px`,
                  }}
                  variants={logoVariants}
                  className='brown-logo'
                />
              </div>
              <motion.section
                style={{ display: `flex`, marginTop: `var(--margin-med)` }}
                className="card-section"
              >
                <ContactCard
                  title={`Send us an Email`}
                  method={`email`}
                  methodContent={`hello@brownlaw.ca`}
                  cardClick={cardClickHandler}
                  formExit={formExit}
                  isFormVisible={isFormVisible}
                />
                {!isFormVisible && (
                  <ContactCard
                    title={`Give us a Call`}
                    method={`phone`}
                    methodContent={`905.555.5555`}
                    primaryBlue={true}
                  />
                )}
              </motion.section>
            </motion.div>
          </>
        )}
        {/* <motion.div className="col-1" key="con3"> */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial="closed"
              animate={isMenuOpen ? `open` : `closed`}
              variants={elephantLoad2}
              exit="closed"
              transition={
                {
                  // duration: 0.2,
                }
              }
              key="con2"
              className="col-1"
              // layoutTransition
            >
              <ElephantFront2 key="el2" />
            </motion.div>
          )}
        </AnimatePresence>
        {/* </motion.div> */}
        {isMenuOpen && (
          <>
            <div className="col-2" >
              <motion.img
                src={brownLogo}
                alt="Brown Logo"
                style={{
                  height: 125,
                  width: `auto`,
                  position: `relative`,
                  left: `-105px`,
                }}
                variants={logoVariants}
                
              />
              <MainMenu />
            </div>
          </>
        )}
      </StyledGridBigContent>
      {/* <div
        className="bgPath"
        style={{
          position: `absolute`,
          bottom: 0,
          left: 0,
          background: `url(${bgPathBottom})`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `50%`,
          zIndex: 1,
        }}
      /> */}
    </Layout>
  )
}

export default Connect
