import React, { useState } from "react"
import { motion } from "framer-motion"
import { StyledContactCard } from "./components.css"
import ContactForm from "../ContactForm"

function ContactCard({
  title,
  method,
  methodContent,
  primaryBlue,
  cardClick,
  formExit,
  isFormVisible,
}) {
  let DisplayContact
  if (method === "email") {
    DisplayContact = <>{methodContent}</>
  } else {
    DisplayContact = (
      <>
        <a style={{ color: `var(--white)` }} href={`tel:${methodContent}`}>
          {methodContent}
        </a>
      </>
    )
  }
  const spring = {
    type: "spring",
    damping: 10,
    stiffness: 100,
  }
  return (
    <StyledContactCard
      primaryBlue={primaryBlue}
      showForm={isFormVisible}
      onClick={method === "email" && cardClick}
      layoutTransition={spring}
      style={{ width: isFormVisible ? `100%` : `325px` }}
    >
      <h2>{title}</h2>
      {isFormVisible && <ContactForm />}
      <p style={{ color: `var(--white)` }}>{DisplayContact}</p>
      {/* {isFormVisible && <span style={{position: `relative`, bottom: 0, right: `-95%`, paddding: 10, zIndex:9999}} onClick={formExit}>x</span>} */}
    </StyledContactCard>
  )
}

export default ContactCard
