import styled, { css } from "styled-components"
import { motion } from "framer-motion"
export const ContactField = styled(motion.div)`
  margin-bottom: var(--margin-small);

  label {
    opacity: 0;
    font-size: 1px;
  }
  input {
    padding: 1rem;
    border: none;
    border-radius: var(--border-radius);
    background: var(--white);
    opacity: 1;
    width: 100%;
  }

  button {
    position: absolute;
    right: 0;
    background: var(--primary-blue);
    color: var(--white);
    border: none;
    border-radius: var(--border-radius);
    padding: 1rem;
    width: 200px;
    cursor: pointer;
    /* transition: var(--transition-hover); */
    /* &:hover {
      color: #000;
      opacity: 0.9;
    } */
  }
`
