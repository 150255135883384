import React, { useState } from "react"
import gql from "graphql-tag"
import { Mutation } from "react-apollo"
import { ContactField } from "./components.css"
function ContactForm() {
  const [step, nextStep] = useState(1)

  const [nameValue, setNameValue] = useState("")
  const [phoneValue, setPhoneValue] = useState("")
  const [emailValue, setEmailValue] = useState("")
  const [messageValue, setMessageValue] = useState("")

  const lastStepHandler = () => {
    nextStep(step + 1)
  }
  const spring = {
    type: "spring",
    damping: 20,
    stiffness: 200,
  }

  return (
    <Mutation mutation={CONTACT_MUTATION}>
      {(formSubmission, { loading, error, data }) => (
        <form
          method="post"
          onSubmit={async event => {
            event.preventDefault()
            formSubmission({
              variables: {
                clientMutationId: "example",
                yourName: nameValue,
                yourEmail: emailValue,
                yourPhone: phoneValue,
                yourMessage: messageValue,
              },
            })
          }}
          style={{ position: `relative`, zIndex: `999` }}
          cancelButton={false}
          submitButton={""}
          disabled={true}
        >
          {step === 1 && (
            <ContactField>
              <label for="name">Name</label>
              <div style={{ position: `relative` }}>
                <input
                  type="text"
                  name="name"
                  placeholder="Your name"
                  onChange={e => setNameValue(e.target.value)}
                />
                <button onClick={() => nextStep(step + 1)}>Next</button>
              </div>
            </ContactField>
          )}

          {step === 2 && (
            <ContactField
              initial={{ width: 350 }}
              animate={{ width: `100%` }}
              layoutTransition={spring}
            >
              <label for="email">Email</label>
              <div style={{ position: `relative` }}>
                <input
                  type="email"
                  name="email"
                  placeholder="hello@abc.ca"
                  onChange={e => setEmailValue(e.target.value)}
                />
                <button
                  onClick={() => nextStep(step + 1)}
                  // initial={{ x: -100 }}
                  // animate={{ x: 0 }}
                  // exit={{ x: -100 }}
                  // layoutTransition={spring}
                >
                  Next
                </button>
              </div>
            </ContactField>
          )}

          {step === 3 && (
            <ContactField
              initial={{ width: 350 }}
              animate={{ width: `100%` }}
              layoutTransition={spring}
            >
              <label for="phone">Phone Number</label>
              <div style={{ position: `relative` }}>
                <input
                  type="text"
                  name="phone"
                  placeholder="(905) 123-4567"
                  onChange={e => setPhoneValue(e.target.value)}
                />
                <button onClick={lastStepHandler}>Next</button>
              </div>
            </ContactField>
          )}

          {step === 4 && (
            <ContactField
              initial={{ width: 350 }}
              animate={{ width: `100%` }}
              layoutTransition={spring}
            >
              <label for="message">Your Message</label>
              <div style={{ position: `relative` }}>
                <input
                  type="text"
                  name="message"
                  placeholder="Your Message"
                  onChange={e => setMessageValue(e.target.value)}
                />

                <button
                  type="submit"
                  initial={{ x: -100 }}
                  animate={{ x: 0 }}
                  exit={{ x: -100 }}
                  layoutTransition={spring}
                >
                  Send
                </button>
              </div>
              {error && (
                <p>An unknown error has occurred, please try again later</p>
              )}
              {data && <p>Message successfully sent!</p>}
            </ContactField>
          )}
        </form>
      )}
    </Mutation>
  )
}

export default ContactForm

const CONTACT_MUTATION = gql`
  mutation CreateSubmissionMutation(
    $clientMutationId: String!
    $yourName: String!
    $yourEmail: String!
    $yourPhone: String!
    $yourMessage: String!
  ) {
    formSubmission(
      input: {
        clientMutationId: $clientMutationId
        yourName: $yourName
        yourEmail: $yourEmail
        yourPhone: $yourPhone
        yourMessage: $yourMessage
      }
    ) {
      success
      data
    }
  }
`
